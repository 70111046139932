/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import moment from 'moment';
import { pluralize, titleize } from 'inflection';
import { Link } from 'ui/components/Common';
import { Vocabs, ChallengeUserChallengeItem, ChallengeItemChallenge } from 'ui/types';
import { getVocab } from 'ui/utils';

interface Props {
  item: ChallengeUserChallengeItem;
  vocabs: Vocabs;
}

const isDisabled = (challenge: ChallengeItemChallenge) => {
  if (challenge.value !== null) { return false; }
  if (moment().isAfter(moment(challenge.registerEndDate).endOf('day'))) { return true; }
  if (moment().isBefore(moment(challenge.registerStartDate).startOf('day'))) { return true; }

  return false;
};

const explicitText = (challenge: ChallengeItemChallenge) => {
  if (challenge.value !== null) { return 'View'; }
  if (moment().isAfter(moment(challenge.registerEndDate).endOf('day'))) { return 'Closed'; }
  if (moment().isBefore(moment(challenge.registerStartDate).startOf('day'))) { return 'Not Yet Open'; }

  return 'Join';
};

const implicitLink = (scopes: string) => {
  switch (scopes) {
    case 'individual': return '/challenges#personal';
    case 'group': return '/challenges#group';
    case 'courses': return '/courses';
    default: return '/challenges';
  }
};

const implicitText = (scopes: string, vocab: string) => {
  switch (scopes) {
    case 'individual': return `View Personal ${vocab}`;
    case 'group': return `View Group ${vocab}`;
    case 'courses': return 'View Courses';
    default: return `View ${vocab}`;
  }
};

const explicitChallenge = (challenge: ChallengeItemChallenge) => (
  <div
    key={challenge.id}
    data-testid={`challenge-${challenge.id}`}
    className="p-3 border border-mid-gray rounded-lg flex flex-col md:flex-row gap-1 md:gap-3 justify-between items-center"
  >
    <div className="w-full md:w-5/6 text-center md:text-start">{challenge.name}</div>

    <div className="w-full xs:w-1/2 lg:w-1/3 text-center md:text-end">
      <Link
        to={challenge.link}
        disabled={isDisabled(challenge)}
        className="block px-2 btn btn-primary"
      >
        {explicitText(challenge)}
      </Link>
    </div>
  </div>
);

export const ChallengeUser: React.FC<Props> = ({ item, vocabs }) => {
  const { status, challengeUserChallengeItemDetails: { qualifiedScopes, challenges } } = item;
  const vocab = titleize(pluralize(getVocab('challenge', vocabs)));

  const renderProgressLink = () => (
    <Link
      to={`/plan_designs/${item.challengeId}/challenge_items/${item.id}`}
      className="text-body hover:underline"
    >
      View Progress
    </Link>
  );

  return (
    <div className="flex flex-col gap-3">
      {qualifiedScopes !== 'explicit' && (
        <React.Fragment>
          <Link
            to={implicitLink(qualifiedScopes)}
            className="mt-2 mx-auto btn btn-primary"
          >
            {implicitText(qualifiedScopes, vocab)}
          </Link>

          {status.progress > 0 && renderProgressLink()}
        </React.Fragment>
      )}

      {qualifiedScopes === 'explicit' && (
        <React.Fragment>
          {challenges.map((challenge: ChallengeItemChallenge) => explicitChallenge(challenge))}
          {status.progress > 0 && renderProgressLink()}
        </React.Fragment>
      )}
    </div>
  );
};

export default ChallengeUser;
