/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { titleize } from 'inflection';
import { Link } from 'ui/components/Common';
import { FormResponseChallengeItem } from 'ui/types';
import { formatDate } from 'ui/utils';
import { itemDisabled } from '../utils';

interface Props {
  item: FormResponseChallengeItem;
}

const statusClass = (status: string): string => {
  if (['in_progress', 'pending'].includes(status)) { return 'text-yellow-500'; }
  if (status === 'approved') { return 'text-green-500'; }
  if (status === 'denied') { return 'text-red-500'; }
  return 'text-gray-500';
};

export const FormResponse: React.FC<Props> = ({ item }) => {
  const { status, formResponseDetails: { responses, formId } } = item;
  const disabled = itemDisabled(item);

  const renderAction = () => {
    if (!!status.maxAttempts && (status.attempts! >= status.maxAttempts)) { return false; }
    return disabled === false;
  };

  const responseLink = (response) => {
    const base = `/forms/${formId}/form_responses/${response.id}`;
    return response.status === 'in_progress' ? `${base}/edit` : base;
  };

  return (
    <div className="mt-2 flex flex-col gap-3">
      {responses.map(response => (
        <div key={response.id} className="border border-mid-gray rounded-lg">
          <div className="flex flex-col md:flex-row">
            <div className="w-full">{formatDate(response.date)}</div>
            <div className={`w-full ${statusClass(response.status)}`}>{titleize(response.status)}</div>
            <div className="w-full">
              <Link to={responseLink(response)}>
                {response.status === 'in_progress' ? 'Continue' : 'View Response'}
              </Link>
            </div>
          </div>

          {!!response.comment && <div className="text-sm mt-2 px-6 md:px-10">{response.comment}</div>}
        </div>
      ))}

      {renderAction() && (
        <Link
          to={`/forms/${formId}/form_responses/new`}
          className="mt-2 mx-auto btn btn-primary"
        >
          Complete {responses.length === 0 ? 'This' : 'Again'}
        </Link>
      )}
    </div>
  );
};

export default FormResponse;
