import {
  ItemStatusCode, ChallengeItemType as ItemType,
  DeviceableItemTypes, PlanDesignChallengeItem,
  ChallengeUserChallengeItem, FormResponseChallengeItem, ProxyChallengeItem,
  LinkVisitChallengeItem, ResourceViewChallengeItem, ScreeningChallengeItem,
} from 'ui/types';

interface PointsData {
  awarded: number;
  total: number;
  fraction: boolean;
}

const disabled = [ItemStatusCode.Locked, ItemStatusCode.NotYetOpen, ItemStatusCode.RecordingClosed];

export const pointsText = ({ awarded, total, fraction }: PointsData): string => {
  if (!fraction) { return total.toString(); }

  return `${awarded}/${total}`;
};

export const itemDisabled = (item: PlanDesignChallengeItem): boolean => {
  const dates = !!item.startDate && !!item.endDate;
  if (!dates) { return disabled.includes(item.status.code); }

  return disabled.includes(item.status.code) || item.datesStatus !== 'current';
};

const statusMap = {
  complete: { textColor: 'text-[#216E48]', bgColor: 'bg-[#E6F3EC]' },
  pending: { textColor: 'text-[#81620C]', bgColor: 'bg-[#F9EED0]' },
  closed: { textColor: 'text-[#212121]', bgColor: 'bg-[#ECEFF1]' },
  denied: { textColor: 'text-[#A40A00]', bgColor: 'bg-[#FCEDE6]' },
  incomplete: { textColor: 'text-[#212121]', bgColor: 'bg-[#ECEFF1]' },
  default: { textColor: 'text-[#212121]', bgColor: 'bg-[#ECEFF1]' },
};

export const mapStatus = (item: PlanDesignChallengeItem) => {
  if (DeviceableItemTypes.includes(item.type) && item.todo?.completed) { return statusMap.complete; }

  switch (item.status.code) {
    case ItemStatusCode.Complete: return statusMap.complete;
    case ItemStatusCode.Pending: return statusMap.pending;
    case ItemStatusCode.Denied: return statusMap.denied;
    case ItemStatusCode.InProgress: return statusMap.pending;
    case ItemStatusCode.RecordingClosed: return statusMap.closed;
    case ItemStatusCode.NotComplete: return statusMap.incomplete;
    default: return statusMap.default;
  }
};

export const showModal = (item: PlanDesignChallengeItem): boolean => {
  let details: { [key: string]: any };

  switch (item.type) {
    case ItemType.Biometric:
    case ItemType.ChallengeUser:
    case ItemType.Combo:
    case ItemType.Entry:
      return true;
    case ItemType.FormResponse:
      details = (item as FormResponseChallengeItem).formResponseDetails;
      return details.responses.length > 0 && item.completionLimit > 1;
    case ItemType.Screening:
      details = (item as ScreeningChallengeItem).screeningDetails;
      return details.screenings.length > 0 && item.completionLimit > 1;
    default:
      return false;
  }
};

export const linkText = (item: PlanDesignChallengeItem): string => {
  let details: { [key: string]: any };

  switch (item.type) {
    case ItemType.FormResponse:
      details = (item as FormResponseChallengeItem).formResponseDetails;
      return details.responses.length === 0 ? 'Complete This' : 'View Response';
    case ItemType.LinkVisit:
      return 'Visit Link';
    case ItemType.ResourceView:
      return 'View Post';
    case ItemType.Proxy:
    case ItemType.Screening:
      return 'Complete This';
    default:
      return 'More Info';
  }
};

export const linkTo = (item: PlanDesignChallengeItem): string => {
  let details: { [key: string]: any };
  let link: string;

  switch (item.type) {
    case ItemType.ChallengeUser:
      return `/challenges#${(item as ChallengeUserChallengeItem).challengeUserChallengeItemDetails.qualifiedScopes}`;
    case ItemType.FormResponse:
      details = (item as FormResponseChallengeItem).formResponseDetails;
      link = `/forms/${details.formId}/form_responses/`;
      return details.responses.length === 0 ? `${link}/new` : `${link}${details.responses[0].id}`;
    case ItemType.LinkVisit:
      return (item as LinkVisitChallengeItem).link;
    case ItemType.Proxy:
      return `/forms/${(item as ProxyChallengeItem).formId}/form_responses/new`;
    case ItemType.ResourceView:
      return (item as ResourceViewChallengeItem).post;
    case ItemType.Screening:
      return `/screenings/new?challenge_item_id=${item.id}`;
    default:
      return '#';
  }
};
