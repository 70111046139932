import React from 'react';

interface Props {
  id?: string | undefined;
  children: React.ReactNode;
  backgroundColor?: string;
  extraClasses?: string;
}

export const Card: React.FC<Props> = ({
  id = undefined, children, backgroundColor = 'bg-white', extraClasses = '',
}) => (
  <section
    id={id}
    className={`flex flex-col grow px-6 py-4 w-full rounded-lg max-md:px-5 max-md:max-w-full ${backgroundColor} ${extraClasses}`}
  >
    {children}
  </section>
);

export default Card;
